import React, { lazy } from 'react'

const CashierClosure = lazy(() => 
  import('./CashierClosure').then(module => ({
    default: module.CashierClosure
  }))
)

export const CashierClosureRoutes = [
  {
    props: { exact: true, path: `/frontdesk/cashierclosure`},
    component: <CashierClosure />,
  }
]