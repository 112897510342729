import React, { lazy } from 'react'
import { ChargesType, FrontDeskType } from '../Common'

const Payment = lazy(() =>
  import('./Payment').then(module => ({
    default: module.Payment,
  }))
)

const PaymentFolio = lazy(() =>
  import('./PaymentFolio').then(module => ({
    default: module.PaymentFolio,
  }))
)
const PaymentDocument = lazy(() =>
  import('./PaymentDocument').then(module => ({
    default: module.PaymentDocument,
  }))
)

const BIDocument = lazy(() =>
  import('../../BusinessInsightModule/BIDigitalDocument/BIDocument').then(module => ({
    default: module.BIDocument,
  }))
)
// const FolioDoc = lazy(() =>
//   import('./FolioDoc').then(module => ({
//     default: module.FolioDoc,
//   }))
// )

//MASTER FOLIO
const MasterPayment = lazy(() =>
  import('../InHouse/MasterFolio/MasterPayment').then(module => ({
    default: module.MasterPayment,
  }))
)
const MasterPaymentFolio = lazy(() =>
  import('./MasterPaymentFolio').then(module => ({
    default: module.MasterPaymentFolio,
  }))
)

//New check in flow due in detail

export const dueInPaymentUrl = `/frontdesk/duein/detail/payment`
export const dueInPaymentFolioUrl = `/frontdesk/duein/detail/payment/folio`

export const dueInPaymentRedirectUrl = `/frontdesk/duein/payment/folio/redirect/:status`
//
export const walkInPaymentUrl = `/frontdesk/walkin/payment`
export const walkInPaymentFolioUrl = `/frontdesk/walkin/payment/folio`
export const walkInPaymentRedirectUrl = `/frontdesk/walkin/payment/folio/redirect/:status`
//
export const dueOutPaymentUrl = `/frontdesk/dueout/detail/payment`
export const dueOutPaymentFolioUrl = `/frontdesk/dueout/detail/payment/folio`
export const dueOutPaymentRedirectUrl = `/frontdesk/dueout/payment/folio/redirect/:status`
//
export const inHousePaymentUrl = `/frontdesk/inhouse/detail/payment`
export const inHousePaymentFolioUrl = `/frontdesk/inhouse/detail/payment/folio`
export const inHouseFolioDocumentUrl = `/frontdesk/inhouse/payment/folio/document`
export const inHousePaymentRedirectUrl = `/frontdesk/inhouse/payment/folio/redirect/:status`

// iN HOUSE Early Checkout
export const inHouseEarlyCheckOutPaymentUrl = `/frontdesk/inhouse/:status/payment`
export const earlyCheckOutPaymentUrl = `/frontdesk/inhouse/earlycheckout/payment`
export const inHouseEarlyCheckOutPaymentFolioUrl = `/frontdesk/inhouse/:status/payment/folio`
export const inHouseEarlyCheckOutPaymentRedirectUrl = `/frontdesk/inhouse/:status/payment/folio/redirect/:status`
// LateCheckout
export const lateCheckoutPaymentRedirectUrl = `/frontdesk/inhouse/latecheckout/payment/redirect/:status`
// Extend Stay
export const extendStayPaymentRedirectUrl = `/frontdesk/inhouse/extendstay/redirect/:status`
//Room Upgrade
export const roomUpgradePaymentRedirectUrl = `/frontdesk/inhouse/roomupgrade/assign/payment/redirect/:status`

//MASTER FOLIO
export const inHouseMasterPaymentUrl = `/frontdesk/inhouse/masterdetail/payment`
export const inHouseMasterPaymentFolioUrl = `/frontdesk/inhouse/masterdetail/payment/folio`



//http://localhost/frontdesk/inhouse/payment/folio/invoice/?orderId=00004&status=EXPIRED
export const PaymentRoutes = [
  {
    props: { exact: true, path: dueInPaymentUrl },
    component: <Payment mode={FrontDeskType.DueIn} />,
  },
  {
    props: { exact: true, path: dueInPaymentFolioUrl },
    component: <PaymentFolio mode={FrontDeskType.DueIn} />,
  },
  {
    props: { exact: true, path: walkInPaymentUrl },
    component: <Payment mode={FrontDeskType.WalkIn} />,
  },
  {
    props: { exact: true, path: walkInPaymentFolioUrl },
    component: <PaymentFolio mode={FrontDeskType.WalkIn} />,
  },
  {
    props: { exact: true, path: dueOutPaymentUrl },
    component: <Payment mode={FrontDeskType.DueOut} />,
  },
  {
    props: { exact: true, path: dueOutPaymentFolioUrl },
    component: <PaymentFolio mode={FrontDeskType.DueOut} />,
  },
  {
    props: { exact: true, path: inHousePaymentUrl },
    component: <Payment mode={FrontDeskType.InHouse} />,
  },
  //MASTER FOLIO
  {
    props: { exact: true, path: inHouseMasterPaymentUrl },
    component: <MasterPayment mode={FrontDeskType.InHouse} />,
  },
  {
    props: { exact: true, path: inHousePaymentFolioUrl },
    component: <PaymentFolio mode={FrontDeskType.InHouse} />,
  },
  //MASTER FOLIO
  {
    props: { exact: true, path: inHouseMasterPaymentFolioUrl },
    component: <MasterPaymentFolio mode={FrontDeskType.InHouse} />,
  },
  {
    props: { exact: true, path: inHouseEarlyCheckOutPaymentUrl },
    component: <Payment mode={FrontDeskType.InHouse} />,
  },
  {
    props: { exact: true, path: earlyCheckOutPaymentUrl },
    component: <Payment mode={FrontDeskType.InHouse} />,
  },
  {
    props: { exact: true, path: inHouseEarlyCheckOutPaymentFolioUrl },
    component: <PaymentFolio mode={FrontDeskType.InHouse} />,
  },
  {
    props: { exact: true, path: dueInPaymentRedirectUrl },
    component: (
      <PaymentDocument mode={FrontDeskType.DueIn} chargesType={'Booking'} />
    ),
  },
  {
    props: { exact: true, path: walkInPaymentRedirectUrl },
    component: (
      <PaymentDocument mode={FrontDeskType.WalkIn} chargesType={'Booking'} />
    ),
  },
  {
    props: { exact: true, path: dueOutPaymentRedirectUrl },
    component: (
      <PaymentDocument mode={FrontDeskType.DueOut} chargesType={'Booking'} />
    ),
  },

  {
    props: { exact: true, path: inHousePaymentRedirectUrl },
    component: (
      <PaymentDocument mode={FrontDeskType.InHouse} chargesType={'Booking'} />
    ),
  },
  {
    props: { exact: true, path: inHouseEarlyCheckOutPaymentRedirectUrl },
    component: (
      <PaymentDocument mode={FrontDeskType.InHouse} chargesType={'Booking'} />
    ),
  },
  {
    props: { exact: true, path: inHouseFolioDocumentUrl },
    component: <BIDocument mode={FrontDeskType.InHouse} type="FOLIO" />,
  },
  // {
  //   props: { exact: true, path: inHouseFolioDocumentUrl },
  //   component: <FolioDoc mode={FrontDeskType.InHouse} type="FOLIO" />,
  // },
  // Late Checkout
  {
    props: { exact: true, path: lateCheckoutPaymentRedirectUrl },
    component: (
      <PaymentDocument
        mode={FrontDeskType.InHouse}
        chargesType={ChargesType.LateCheckOut}
      />
    ),
  },

  // Extend Stay
  {
    props: { exact: true, path: extendStayPaymentRedirectUrl },
    component: (
      <PaymentDocument
        mode={FrontDeskType.InHouse}
        chargesType={ChargesType.ExtendStay}
      />
    ),
  },
  // Room Upgrade
  {
    props: { exact: true, path: roomUpgradePaymentRedirectUrl },
    component: (
      <PaymentDocument
        mode={FrontDeskType.InHouse}
        chargesType={ChargesType.RoomUpgrade}
      />
    ),
  },
]
